import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../common/components/Text';
import Button from '../../common/components/Button';
import Heading from '../../common/components/Heading';
import styled from 'styled-components';
import {
    BannerContent,
    ButtonGroup,
} from './banner.style';
import { Image } from 'antd';
import MainBannerImage from '../../common/images/BannerImage.png';


const BannerWrapper = styled.section`
  width : 100%;
  background: #f9f9f9;
 @media (max-width: 768px) {
    padding: 5rem 0 0 0;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const BannerImageWrapper = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5rem 0 5rem 5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BannerSection = ( { clientConfig } ) => {
    return (
        <BannerWrapper>
            <Container>
                <BannerContent>
                    <Fade up delay={ 100 }>
                        <Heading
                            as="h1"
                            content="How Ready Are You for the Ivy League? Let’s Find Out!"
                        />
                    </Fade>
                    <Fade up delay={ 200 }>
                        <Text content="Uncover your strengths, refine your application, and take strategic steps toward your dream college with a personalised roadmap." />
                    </Fade>
                    <Fade up delay={ 300 }>
                        <ButtonGroup>
                            <Button style={ { backgroundColor: clientConfig?.primary_color } } title="Let's Find Out" />
                        </ButtonGroup>
                    </Fade>
                </BannerContent>
                <BannerImageWrapper>
                    <Image src={ MainBannerImage } preview={ false } width={ 900 } alt="Banner" />
                </BannerImageWrapper>
            </Container>
        </BannerWrapper>
    );
};

export default BannerSection;
