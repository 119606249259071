import feature1 from '../common/assets/feature1.png';
import feature2 from '../common/assets/feature2.png';
import feature3 from '../common/assets/feature3.png';
// import feature4 from '../common/assets/feature4.png';
// import feature5 from '../common/assets/feature5.png';
// import feature6 from '../common/assets/feature6.png';

import globalCarerLogo from '../common/assets/gsLogo.png'


export const menu_items = {
    leftMenuItems: [
        {
            label: 'How does it work?',
            path: '#home',
            offset: '70',
        },
        {
            label: 'Assessment Report',
            path: '#report',
            offset: '70',
        },
        {
            label: 'Testimonoial',
            path: '#testimonoial',
            offset: '70',
        },
        {
            label: 'FAQ',
            path: '#faq',
            offset: '70',
        },
    ],
    rightMenuItems: [
        {
            label: 'Login',
            path: '#home',
            offset: '70',
        },
        {
            label: 'Sign Up',
            path: '#home',
            offset: '70',
        },
    ],
    mobileMenuItems: [
        {
            label: 'How does it work?',
            path: '#home',
            offset: '70',
        },
        {
            label: 'Assessment Report',
            path: '#report',
            offset: '70',
        },
        {
            label: 'Testimonoial',
            path: '#testimonoial',
            offset: '70',
        },
        {
            label: 'FAQ',
            path: '#faq',
            offset: '70',
        },
    ]
};

export const USERTESTIMONIALS = [
    {
        title: "Modern look & trending design",
        review:
            "I achieved a higher score in my school exams thanks to LetsUnbound. I believe my learning journey has improved significantly after joining the course and has helped in improving my concepts.",
        name: "Aratrika",
        designation: "USA, Grade 7",
    },
    {
        title: "Modern look & trending design",
        review:
            "The classes give me the ability to stay on track and not forget anything because of reveiws. The classes are also very engaging and interactive.",
        name: "Rishi",
        designation: "USA, Grade 5",
    },
    {
        title: "Modern look & trending design",
        review:
            "Raymond, initially struggled with math basics. With Lets Unbound's math course he strengthed his foundation and boosted his understanding in just one month.",
        name: "Raymond",
        designation: "United Kingdom",
    },
    {
        title: "Modern look & trending design",
        review:
            "Witness how Aarush's father wholeheartedly commends the exceptional course, praising the dedicated faculties and engaging curriculum as a true benchmark of excellence.",
        name: "Aarush's Father",
        designation: "",
    },
    {
        title: "Modern look & trending design",
        review:
            "What keep me coming back enrolling is because I see the benefits. There are strategies that are helping the children.",
        name: "Shrishti's Mother",
        designation: "",
    },
];

export const faq = [
    {
        id: 1,
        expend: true,
        title: 'What is the Ivy League Readiness Assessment?',
        description: 'This assessment evaluates your academic performance, test scores, extracurricular engagement, and personal values to determine how competitive your profile is for Ivy League admissions. You’ll receive a detailed report with insights and tailored recommendations to strengthen your application.',
    },
    {
        id: 2,
        title: 'Who should take this assessment?',
        description: 'This assessment is designed for school students who aspire to apply to Ivy League colleges. Whether you\'re in the early stages of preparation or finalizing your application, this tool helps identify strengths and areas for improvement.',
    },
    {
        id: 3,
        title: 'How accurate is the assessment?',
        description: 'The assessment is meticulously designed by study abroad experts with deep knowledge of Ivy League admissions criteria. While it doesn’t guarantee acceptance, it provides data-driven insights to help you craft a more competitive application.',
    },
    {
        id: 4,
        title: 'How long does the assessment take?',
        description: 'It takes approximately 15-20 minutes to complete. Answer honestly to ensure the most accurate and useful recommendations.',
    },
    {
        id: 5,
        title: 'Can I retake the assessment after improving my profile?',
        description: 'Yes! We recommend retaking the assessment after making significant improvements in academics, test scores, extracurriculars, or personal development. This helps track progress and refine your strategy.',
    },
    {
        id: 6,
        title: 'What should I do after receiving my report?',
        description: 'Use the insights to prioritize improvements, set SMART goals, seek mentorship, and refine your application strategy. Your next steps will depend on your score and personalized recommendations.',
    }
];



export const contactInfo = {
    contactInfo: {
        title: 'Need help? We are here for you',
        phone: `+6012-5037122`,
        email: `info@qstudyworld.com`,
    },
};

export const FOOTER_DATA = {
    strideLogo: globalCarerLogo,
    logo: globalCarerLogo,
    menu: [
        {
            link: '#',
            label: 'Support',
        },
        {
            link: '#',
            label: 'About Us',
        },
        {
            link: '#',
            label: 'Privacy',
        },
        {
            link: '#',
            label: 'Contact',
        },
    ],
    social: [
        {
            icon: globalCarerLogo,
            link: '#',
        },
    ],
};

export const Timeline = [
    {
        title: 'Take the Assessment',
        description:
            'Answer key questions about your academics, test scores, extracurriculars, and personal values to assess your Ivy League readiness.',
    },
    {
        title: 'Get Your Readiness Report',
        description:
            'Unlock a detailed, personalised report highlighting your strengths, areas for improvement, and actionable next steps.',
    },
    {
        title: 'Plan Your Path with Strategy & Mentorship',
        description:
            'Use your report as a roadmap to refine your application, enhance your profile, and seek mentorship that guides you toward Ivy League success.',
    },
];

export const Features = [
    {
        id: 1,
        icon: feature1,
        title: 'Know Where You Stand Early',
        description:
            'Understanding your current readiness level allows you to strategically tailor your application well in advance, ensuring you focus on the right areas for improvement.',
    },
    {
        id: 2,
        icon: feature2,
        title: 'Designed by Study Abroad Experts',
        description:
            'This assessment has been meticulously crafted by admissions professionals with years of experience, ensuring you receive guidance that aligns with what top universities truly seek.',
    },
    {
        id: 3,
        icon: feature3,
        title: 'Eliminate Guesswork in the Admissions Process',
        description:
            'With expert-backed insights, you’ll have clarity on the admissions landscape, so you can confidently work on areas that will truly enhance your chances.',
    },
];