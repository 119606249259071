import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from '../../common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { androidMenu } from 'react-icons-kit/ionicons/androidMenu';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/ContainerTwo';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import { Image } from 'antd';
import { menu_items } from '../../data';
import styled from 'styled-components';

const MainCtaButton = styled.button `
  padding: 1rem 1.5rem;
  border-radius: 10px;
  border : unset; 
  font-size: 18px;
  font-weight: 700;
cursor : pointer;

  @media (max-width: 700px) {
    width: 100%;
    text-align: center;
  }
`;

const Navbar = ( { clientConfig } ) => {
    const [mobileMenu, setMobileMenu] = useState( false );

    const scrollItems = [];

    menu_items.leftMenuItems.forEach( ( item ) => {
        scrollItems.push( item.path.slice( 1 ) );
    } );

    const handleMobileMenu = () => {
        setMobileMenu( !mobileMenu );
    };

    const handleHandleMenuClose = () => {
        setMobileMenu( false );
    };

    const handleProceedToTest = () => {
        const currentUrlParams = new URLSearchParams( window.location.search );
        const baseUrl = 'https://careertest.qstudyworld.com/qstudyworld/test/signup/?test_id=Ttd2fAlfZwc';
        const newUrl = currentUrlParams?.size === 0 ? `${ baseUrl }` : `${ baseUrl }&${ currentUrlParams.toString() }`;
        window.open( newUrl, '_blank' );
    }

    return (
        <NavbarWrapper className="agencyModern-navbar navbar">
            <Container>
                <Image
                    src={ clientConfig?.logo }
                    title="Crypto Modern"
                    className="main-logo"
                    preview={ false }
                    width={ 250 }
                />

                <MenuArea>
                    <ScrollSpyMenu
                        className="menu-items menu-left"
                        menuItems={ menu_items.leftMenuItems }
                        offset={ -84 }
                    />
                    <MainCtaButton className='menu-items menu-right' onClick={ handleProceedToTest } style={ { backgroundColor: clientConfig?.primary_color, color: 'white' } }>
                        Start Your Assessment!
                    </MainCtaButton>

                    <Button
                        className="menubar"
                        icon={
                            mobileMenu ? (
                                <Icon
                                    style={ { color: '#02073E' } }
                                    className="bar"
                                    size={ 32 }
                                    icon={ androidClose }
                                />
                            ) : (
                                <Fade>
                                    <Icon
                                        style={ { color: '#02073E' } }
                                        className="close"
                                        icon={ androidMenu }
                                        size={ 32 }
                                        />
                                </Fade>
                            )
                        }
                        color="#0F2137"
                        variant="textButton"
                        onClick={ handleMobileMenu }
                    />
                </MenuArea>
            </Container>

            <MobileMenu className={ `mobile-menu ${ mobileMenu ? 'active' : '' }` }>
                <Container>
                    <Scrollspy
                        className="menu"
                        items={ scrollItems }
                        offset={ -84 }
                        currentClassName="active"
                    >
                        { menu_items.mobileMenuItems.map( ( menu, index ) => (
                            <li key={ `menu_key${ index }` }>
                                <AnchorLink
                                    href={ menu.path }
                                    offset={ menu.offset }
                                    onClick={ handleHandleMenuClose }
                                >
                                    { menu.label }
                                </AnchorLink>
                            </li>
                        ) ) }
                    </Scrollspy>
                </Container>
            </MobileMenu>
        </NavbarWrapper>
    );
};

export default Navbar;