import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RingLoader } from 'react-spinners';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/qstudy.style';
import BannerSection from '../components/Banner/index';
import Footer from '../components/Footer';
import UserTestimonialSection from '../components/UserTestimonials';
import PartnerSection from '../components/Partner';
// import StickyButton from '../components/StickyButton';
import FaqSection from '../components/FaqSection';
import TimelineSection from '../components/Timeline';
import FeatureSection from '../components/ServiceSection';
import useFetchClientConfig from '../api/FetchClientConfig';
import Navbar from '../components/Navbar';
import { DrawerProvider } from '../common/contexts/DrawerContext';

const CareerInterestLetsUnbound = () => {

    const { response, error, isLoading, fetchClientConfig } = useFetchClientConfig();
    const clientName = 'letsunbound';

    useEffect( () => {
        fetchClientConfig( clientName );
    }, [] )

    return (
        <>
            {
                ( isLoading ) ?
                    <div style={ { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>
                        <RingLoader color="#1a64d6" />
                    </div>
                    :
                    <ThemeProvider theme={ saasClassicTheme }>
                        <Fragment>
                            <Helmet>
                                { response?.data?.favicon && (
                                    <link rel="icon" href={ response.data.favicon } type="image/x-icon" />
                                ) }
                            </Helmet>
                            <ResetCSS />
                            <GlobalStyle />
                            <ContentWrapper>
                                <Sticky top={ 0 } innerZ={ 9999 } activeClass="sticky-nav-active">
                                    <DrawerProvider>
                                        <Navbar clientConfig={ response?.data } />
                                    </DrawerProvider>
                                </Sticky>
                                <BannerSection clientConfig={ response?.data } />
                                <TimelineSection />
                                <FeatureSection />
                                <PartnerSection clientConfig={ response?.data } />
                                <UserTestimonialSection />
                                <FaqSection />
                                <Footer clientConfig={ response?.data } />
                                {/* <StickyButton clientConfig={ response?.data } /> */ }
                            </ContentWrapper>
                        </Fragment>
                    </ThemeProvider>

            }

        </>

    );
};

export default CareerInterestLetsUnbound;
