import CareerInterestLetsUnbound from './RoutesContainer/RouteContainer';
import './App.css';

function App() {
    return (
        <>
            <CareerInterestLetsUnbound />
        </>
    );
}

export default App;
