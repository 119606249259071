import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const BannerContent = styled.div `
  width: 54%;
  padding : 20rem 10rem 20rem 10rem;
  flex-shrink: 0;
  @media only screen and (max-width: 1700px) {
    width: 48%;
  padding : 10rem 0rem 10rem 5rem;
  }
  @media only screen and (max-width: 1219px) {
    flex-shrink: 0;
    width: 56%;
    margin-top: 0px;
  }
  @media only screen and (max-width: 768px) {
    width: 80%;
  padding : 30px;
  }
  @media only screen and (max-width: 624px) {
    width: 100%;
  padding : 30px;
  }
  h1 {
    font-size: 56px;
    line-height: 1.25;
    font-weight: 700;
    color: ${themeGet( 'colors.headingColor', '#0F2137' )};
    margin-bottom: 20px;
    letter-spacing: -2px;
    @media only screen and (max-width: 1366px) {
      font-size: 52px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 36px;
      line-height: 1.35;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 20px;
    line-height: 2;
    color: ${rgba( '#02073e', 0.8 )};
    padding-right: 70px;
    @media only screen and (max-width: 1366px) {
      font-size: 18px;
      padding-right: 0;
    }
    @media only screen and (max-width: 1199px) {
      padding-right: 50px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 16px;
      line-height: 1.8;
    }
    @media only screen and (max-width: 480px) {
      padding-right: 0;
    }
  }
`;

export const ButtonGroup = styled.div `
  margin-top: 28px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .reusecore__button {
    text-transform: inherit;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    &.primary {
      background-color: ${themeGet( 'colors.primary', '#6C247E' ) };
      color: ${ themeGet( 'colors.white' ) };
      font-size: 15px;
      min-height: 55px;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(108, 36, 126, 0.57);
      }
    }

    &.text {
      font-size: 17px;
      color: ${ themeGet( 'colors.priamry', '#6C247E' )};
      letter-spacing: -0.3px;
      font-weight: 700;
      margin-left: 5px;
      .btn-icon {
        svg {
          width: auto;
          height: 32px;
        }
      }
    }
  }
`;